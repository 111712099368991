import React from 'react';
import * as S from './mainsectiontext.styled';

const MainSectionText = () => {
  return (
    <S.Text>Somos especializados em posicionar Empresas Locais “frente a frente” com seu cliente no momento da compra através de Estratégias Online.</S.Text>
  );
}

export default MainSectionText;
